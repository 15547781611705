import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { navigate } from 'gatsby';
import { Typography } from '../typography/Typography';
import { Spacer } from '../spacer/Spacer';
import { HtmlContent } from '../htmlContent/HtmlContent';
import { Button } from '../button/Button';
import { Divider } from '../divider/Divider';
import { PageContext } from '../../context/pageContext';
import useSubmitFormMutation, {
  UmbracoFormData,
} from '../../hooks/useSubmitUmbracoForm';
import { Textarea } from '../form/Textarea/Textarea';
import { Checkbox } from '../form/Checkbox/Checkbox';
import { Input } from '../form/Input/Input';
import { Select } from '../form/Select/Select';
import {
  ButtonSelect,
  ButtonSelectOptions,
} from '../form/buttonSelect/ButtonSelect';

// Interface

interface FormFieldRendererProps {
  fields: Field[];
  dirtyFields: any; // TODO type better
  errors: any; // TODO type better
  register: any; // TODO type better
}

interface FormData {
  [key: string]: any;
}

interface PreValue {
  caption: string;
  value: string;
}

interface Setting {
  key: string;
  value: string;
}

interface Field {
  alias: string;
  caption: string;
  fieldTypeId: string;
  id: string;
  invalidErrorMessage: string | null;
  mandatory: boolean;
  preValues: PreValue[];
  requiredErrorMessage: string | null;
  tooltip: string | null;
  settings: Setting[];
}

export interface FormRendererProps {
  formGuid: string;
  hideModal: any;
  form: {
    successMessage: string;
    submitLabel: string;
    invalidErrorMessage: string;
    name: string;
    allFields: Field[];
    pages: Page[];
    urlOnSubmit?: string;
  };
}

export interface Page {
  id: string;
  fieldSets: FieldSet[];
}

export interface FieldSet {
  id: string;
  caption: string;
  containers: Container[];
}

export interface Container {
  width: string;
  caption: string;
  fields: Field[];
}

interface FormattedFormDataObject {
  field: string;
  value: string;
}

const StyledForm = styled.form`
  ${({ theme: { media, space, colors } }) => css`
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  `};
`;

const StyledFieldset = styled.div`
  ${({ theme: { media, space, colors } }) => css`
    display: grid;
    gap: ${space.small};
    @media (min-width: ${media.medium}) {
      grid-template-columns: repeat(12, 1fr);
    }
  `};
`;

const StyledContainer = styled.div`
  ${({ theme: { media, space, colors }, width }) => css`
    @media (min-width: ${media.medium}) {
      grid-column: span ${width || 12};
    }
  `};
`;

// JSX

const FormFieldRenderer: FC<FormFieldRendererProps> = ({
  fields,
  dirtyFields,
  errors,
  register,
}) => (
  <Spacer>
    {fields.map((formField) => {
      if (!formField) {
        return null;
      }
      // Settings
      const placeholder = formField?.settings?.find(
        (setting) => setting?.key === 'Placeholder'
      )?.value;

      const type = formField?.settings.find(
        (setting) => setting.key === 'FieldType'
      )?.value;

      const defaultValue = formField?.settings.find(
        (setting) => setting.key === 'DefaultValue'
      )?.value;

      const maxLength = formField?.settings.find(
        (setting) => setting.key === 'MaximumLength'
      )?.value;

      const hideLabel =
        formField?.settings.find((setting) => setting.key === 'ShowLabel')
          ?.value === 'False';

      // Text area

      const numberOfRow = formField?.settings.find(
        (setting) => setting.key === 'NumberOfRows'
      )?.value;

      const hintText = formField?.tooltip;

      // Title and description

      const heading = formField?.settings.find(
        (setting) => setting.key === 'Caption'
      )?.value;

      const bodyText = formField?.settings.find(
        (setting) => setting.key === 'BodyText'
      )?.value;

      const headingTag = formField?.settings.find(
        (setting) => setting.key === 'CaptionTag'
      )?.value;

      // Content

      const html = formField?.settings.find(
        (setting) => setting.key === 'Html'
      )?.value;

      // Consent checkbox

      const consentCopy = formField?.settings.find(
        (setting) => setting.key === 'AcceptCopy'
      )?.value;

      // Select options

      const options: Options[] = formField?.preValues.map((item) => ({
        label: item.caption || item.value,
        value: item.value,
      }));

      // Validation

      const invalid = !!errors[formField.alias];
      const valid = dirtyFields[formField.alias] && !errors[formField.alias];

      return (
        <div key={formField.id}>
          {/* Short text (aska input) */}

          {formField.fieldTypeId === '3f92e01b-29e2-4a30-bf33-9df5580ed52c' && (
            <Input
              {...register(formField.alias, {
                required: formField.mandatory,
              })}
              invalid={invalid}
              valid={valid}
              label={formField.caption}
              type={type}
              id={formField.alias}
              placeholder={placeholder}
              defaultValue={defaultValue}
              maxLength={Number(maxLength)}
              required={formField.mandatory}
              hideLabel={hideLabel}
              hintText={hintText || ''}
            />
          )}

          {/* Long text (aka Text area) */}
          {formField.fieldTypeId === '023f09ac-1445-4bcb-b8fa-ab49f33bd046' && (
            <Textarea
              {...register(formField.alias, {
                required: formField.mandatory,
              })}
              invalid={invalid}
              valid={valid}
              label={formField.caption}
              type={type}
              id={formField.alias}
              placeholder={placeholder}
              defaultValue={defaultValue}
              maxLength={Number(maxLength)}
              required={formField.mandatory}
              hideLabel={hideLabel}
              rows={Number(numberOfRow)}
              hintText={hintText || ''}
            />
          )}

          {/* Drop down (aka select) */}

          {formField.fieldTypeId === '0dd29d42-a6a5-11de-a2f2-222256d89593' && (
            <Select
              {...register(formField.alias, {
                required: formField.mandatory,
              })}
              id={formField.alias}
              options={options}
              label={formField.caption}
              placeholder={placeholder}
              hintText={hintText || ''}
              hideLabel={hideLabel}
              invalid={invalid}
            />
          )}

          {/* Single checkbox */}

          {formField.fieldTypeId === 'd5c0c390-ae9a-11de-a69e-666455d89593' && (
            <Spacer gap="xSmall">
              <Checkbox
                id={formField.alias}
                {...register(formField.alias, {
                  required: formField.mandatory,
                })}
                label={formField.caption}
                invalid={invalid}
                valid={valid}
              />
              {hintText && (
                <Typography component="p" variant="bodyMedium">
                  {hintText}
                </Typography>
              )}
            </Spacer>
          )}

          {/* Consent checkbox */}

          {formField.fieldTypeId === 'a72c9df9-3847-47cf-afb8-b86773fd12cd' && (
            <Spacer>
              <Checkbox
                id={formField.alias}
                {...register(formField.alias, {
                  required: formField.mandatory,
                })}
                label={consentCopy}
                invalid={invalid}
                valid={valid}
              />
              {hintText && (
                <Typography component="p" variant="bodyMedium">
                  {hintText}
                </Typography>
              )}
            </Spacer>
          )}

          {/* Title and description */}

          {formField.fieldTypeId === 'e3fbf6c4-f46c-495e-aff8-4b3c227b4a98' && (
            <Spacer>
              <Divider />
              <Spacer>
                {heading && (
                  <Typography
                    component={headingTag || 'h3'}
                    variant="headingSmall"
                  >
                    {heading}
                  </Typography>
                )}
                {bodyText && <HtmlContent>{bodyText}</HtmlContent>}
              </Spacer>
            </Spacer>
          )}

          {/* Html  */}

          {formField.fieldTypeId === '1f8d45f8-76e6-4550-a0f5-9637b8454619' && (
            <Spacer>{html && <HtmlContent>{html}</HtmlContent>}</Spacer>
          )}

          {/* Single choice */}
          {formField.fieldTypeId === '903df9b0-a78c-11de-9fc1-db7a56d89593' && (
            <Controller
              name={formField.alias}
              rules={{ required: formField.mandatory }}
              render={({ field }) => (
                <ButtonSelect
                  label={formField.caption}
                  id={formField.alias}
                  invalid={invalid}
                  hintText={hintText || ''}
                  hideLabel={hideLabel}
                  defaultValue={defaultValue}
                  options={options as ButtonSelectOptions[]}
                  {...field}
                />
              )}
            />
          )}
        </div>
      );
    })}
  </Spacer>
);

export const FormRenderer: FC<FormRendererProps> = ({
  form,
  formGuid,
  hideModal,
}) => {
  const { pageID } = useContext(PageContext)!;
  const [successState, setSuccessState] = useState(false); // TEMP UNTIL MUTATIONS WORK
  const submitUmbracoForm = useSubmitFormMutation();
  const { isLoading, isError, isSuccess } = submitUmbracoForm;
  const [isHuman, setIsHuman] = useState(false); // To track reCAPTCHA verification
  // Get the reCAPTCHA executeRecaptcha function from the hook
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('submiform');

    if (token) {
      setIsHuman(true);
    }
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const formConfig = form;

  const formMethods = useForm({
    mode: 'onSubmit',
  });

  const { register, handleSubmit, watch, reset, setFocus, formState, control } =
    formMethods;

  // Use effect which checks if the form has been submitted successfully

  useEffect(() => {
    if (isSuccess) {
      setSuccessState(true);
      reset();
      if (hideModal) {
        hideModal();
      }

      if (form.urlOnSubmit) {
        navigate(form.urlOnSubmit);
      }
    }
  }, [isSuccess, reset]);

  const { errors, dirtyFields } = formState;

  const onSubmit = async (formData: FormData) => {
    if (!isHuman) {
      return;
    }
    const transformFormData = (
      inputObject: FormData
    ): FormattedFormDataObject[] =>
      Object.keys(inputObject).map((key) => ({
        field: key,
        value: inputObject[key] ? inputObject[key] : 'undefined',
      }));

    const mutationData = {
      formId: formGuid,
      umbracoPageId: pageID,
      fields: transformFormData(formData),
    };

    submitUmbracoForm.mutate(mutationData as UmbracoFormData);
  };

  return (
    <FormProvider {...formMethods}>
      {isSuccess || successState ? (
        <HtmlContent>{formConfig?.successMessage}</HtmlContent>
      ) : (
        <StyledForm onSubmit={handleSubmit(onSubmit)} method="post" noValidate>
          <Spacer>
            <Spacer>
              {form?.pages?.map((page) => (
                <Spacer key={page.id}>
                  {page.fieldSets.map((fieldSet) => (
                    <Spacer>
                      {fieldSet.caption && (
                        <Typography component="h3" variant="headingMedium">
                          {fieldSet.caption}
                        </Typography>
                      )}
                      <StyledFieldset>
                        {fieldSet.containers.map((container, index) => (
                          <StyledContainer width={container.width} id={index}>
                            <FormFieldRenderer
                              fields={container.fields}
                              dirtyFields={dirtyFields}
                              errors={errors}
                              register={register}
                            />
                          </StyledContainer>
                        ))}
                      </StyledFieldset>
                      <Divider />
                    </Spacer>
                  ))}
                </Spacer>
              ))}
            </Spacer>
            {!isHuman && (
              <Button
                type="button"
                onClick={handleReCaptchaVerify}
                variant="primaryInverseOutline"
                label="Verify reCAPTCHA"
              />
            )}

            <Typography variant="bodySmall">
              This site is protected by reCAPTCHA and the Google{' '}
              <a href="https://policies.google.com/privacy">Privacy Policy</a>{' '}
              and{' '}
              <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
              apply.
            </Typography>

            <Button
              type="submit"
              label={formConfig?.submitLabel || 'Submit'}
              disabled={!isHuman}
            />

            {isError && (
              <p>Something went wrong - please try again or contact us</p>
            )}
          </Spacer>
        </StyledForm>
      )}
    </FormProvider>
  );
};

export default FormRenderer;
